import React, { useState, useEffect } from 'react';
import { Layout, Input, Table, Button, Dropdown, Menu, Typography, Tabs, Tag } from 'antd';
import { PlusOutlined, SearchOutlined, EllipsisOutlined, DownOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import { fetchJobs, deleteJob } from '../../api';
import './JobManagement.css';

const { Content } = Layout;
const { Title } = Typography;
const { TabPane } = Tabs;

const JobManagement = () => {
  const [dataSource, setDataSource] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const navigate = useNavigate();
  const location = useLocation(); // Get the location object

  // Fetch jobs every time the component mounts or when the location changes (i.e., forward/back navigation)
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const jobsResponse = await fetchJobs();
        const jobsData = jobsResponse.data.map((job) => ({ ...job, key: job.id }));
        setDataSource(jobsData);
        filterData(jobsData, activeTabKey);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchInitialData();
  }, [location]); // Add location as a dependency to trigger fetch on navigation

  useEffect(() => {
    filterData(dataSource, activeTabKey);
  }, [dataSource, activeTabKey]);

  const filterData = (data, tabKey) => {
    let filteredJobs = [];
    switch (tabKey) {
      case "2":
        filteredJobs = data.filter(job => job.status === 'Draft');
        break;
      case "3":
        filteredJobs = data.filter(job => job.status === 'Published');
        break;
      case "4":
        filteredJobs = data.filter(job => job.status === 'Filled');
        break;
      case "5":
        filteredJobs = data.filter(job => job.status === 'Open');
        break;
      case "6":
        filteredJobs = data.filter(job => job.status === 'Closed');
        break;
      default:
        filteredJobs = data;
    }
    setFilteredData(filteredJobs);
  };

  const handleEdit = (record) => {
    console.log('Edit record:', record);
  };

  const handleDelete = async (key) => {
    try {
      await deleteJob(key);
      setDataSource(dataSource.filter((item) => item.key !== key));
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  const handleViewDetails = (record) => {
    navigate(`/dashboard/job-details`, { state: { job: record } });
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Assignment Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Location',
      dataIndex: ['address', 'city'],
      key: 'location',
      render: (city, record) => `${city}, ${record.address.state}`,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate) => new Date(startDate).toLocaleDateString(),
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      render: (position) => (
        <Tag color={position === 'Needed' ? 'orange' : 'blue'}>{position || '-'}</Tag>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'Published' ? 'blue' : 'orange'}>{status}</Tag>
      ),
    },
    {
      title: 'View',
      key: 'view',
      render: (text, record) => <a onClick={() => handleViewDetails(record)}>Details</a>,
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1" onClick={() => handleEdit(record)}>
                Edit
              </Menu.Item>
              <Menu.Item key="2" onClick={() => handleDelete(record.key)}>
                Delete
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <EllipsisOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ];

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  return (
    <Content
      className="site-layout-background"
      style={{
        margin: '24px 16px',
        padding: 24,
        minHeight: 280,
        backgroundColor: '#fff',
      }}
    >
      <div className="job-management-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <div>
          <Title level={3}>Manage Assignments</Title>
        </div>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)}>
          Create New Assignment
        </Button>
      </div>

      <Tabs defaultActiveKey="1" size="large" onChange={handleTabChange}>
        <TabPane tab={<span>All <Tag>{dataSource.length}</Tag></span>} key="1">
          <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Input
              placeholder="Search assignments"
              prefix={<SearchOutlined />}
              style={{ width: '300px' }}
            />
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">Sort by Date</Menu.Item>
                  <Menu.Item key="2">Sort by Title</Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button>
                Sort by <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          <Table dataSource={filteredData} columns={columns} pagination={{ pageSize: 10 }} />
        </TabPane>
        <TabPane tab={<span>Draft <Tag>{dataSource.filter(job => job.status === 'Draft').length}</Tag></span>} key="2">
          <Table dataSource={filteredData} columns={columns} pagination={{ pageSize: 10 }} />
        </TabPane>
        <TabPane tab={<span>Published <Tag>{dataSource.filter(job => job.status === 'Published').length}</Tag></span>} key="3">
          <Table dataSource={filteredData} columns={columns} pagination={{ pageSize: 10 }} />
        </TabPane>
        <TabPane tab={<span>Filled <Tag>{dataSource.filter(job => job.status === 'Filled').length}</Tag></span>} key="4">
          <Table dataSource={filteredData} columns={columns} pagination={{ pageSize: 10 }} />
        </TabPane>
        <TabPane tab={<span>Open <Tag>{dataSource.filter(job => job.status === 'Open').length}</Tag></span>} key="5">
          <Table dataSource={filteredData} columns={columns} pagination={{ pageSize: 10 }} />
        </TabPane>
        <TabPane tab={<span>Closed <Tag>{dataSource.filter(job => job.status === 'Closed').length}</Tag></span>} key="6">
          <Table dataSource={filteredData} columns={columns} pagination={{ pageSize: 10 }} />
        </TabPane>
      </Tabs>
    </Content>
  );
};

export default JobManagement;
