import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { message, Button } from 'antd';
import { getAuth, applyActionCode } from 'firebase/auth';
import './auth.css'; // Import the CSS file

const VerifyEmail = () => {
  const [verified, setVerified] = useState(false);
  const auth = getAuth();
  const location = useLocation();
  const actionCode = new URLSearchParams(location.search).get('oobCode');

  const handleVerifyEmail = async () => {
    try {
      await applyActionCode(auth, actionCode);
      setVerified(true);
      message.success('Email verified successfully!');
    } catch (error) {
      message.error('Invalid or expired action code.');
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-background"></div>
      <div className="auth-form-container">
        <h2>Verify Email</h2>
        {!verified ? (
          <Button type="primary" onClick={handleVerifyEmail} className="auth-form-button" size="large">
            Verify Email
          </Button>
        ) : (
          <div>
            <p>Your email has been verified. You can now <Link to="/login">login</Link>.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
