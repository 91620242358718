import React, { useEffect, useState } from 'react';
import { Card, Typography, Row, Col, Tag, Button, Divider, message, Table } from 'antd';
import { useLocation } from 'react-router-dom';
import { getAssignmentDocunebt, getAssginmentTimesheet, approveAssginmentTimesheet,updateAssignment } from './../../api';

import './CandidateDetails.css';

const { Title, Text, Link } = Typography;

const CandidateDetails = () => {
  const location = useLocation();
  const { candidate } = location.state;
  const [documents, setDocuments] = useState([]);
  const [timesheet, setTimesheet] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // State for selected rows
  const [status, setStatus] = useState(candidate?.status);

  useEffect(() => {
    const fetchAssignmentData = async () => {
      try {
        const document = await getAssignmentDocunebt(candidate?._id);
        setDocuments(document.data?.documents);
        
        const timesheetResponse = await getAssginmentTimesheet(candidate._id);
        setTimesheet(timesheetResponse.data);
      } catch (error) {
        console.error('Failed to fetch assignment data:', error);
      }
    };
    console.log("candidate",candidate)

    fetchAssignmentData();
  }, [candidate._id]);

  const handleApprove = async (record) => {
    try {
      await approveAssginmentTimesheet(record.assignment, { timecardIds: [record._id] });
      message.success('Timesheet approved successfully');
      // Update the timesheet status locally
      setTimesheet(timesheet.map(item => 
        item._id === record._id ? { ...item, status: 'approved' } : item
      ));
    } catch (error) {
      message.error('Failed to approve timesheet');
    }
  };

  const handleBulkApprove = async () => {
    try {
      await approveAssginmentTimesheet(candidate._id, { timecardIds: selectedRowKeys });
      message.success('Selected timesheets approved successfully');
      // Update the timesheet status locally
      setTimesheet(timesheet.map(item => 
        selectedRowKeys.includes(item._id) ? { ...item, status: 'approved' } : item
      ));
      setSelectedRowKeys([]); // Clear selection after approval
    } catch (error) {
      message.error('Failed to approve selected timesheets');
    }
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Clock In',
      dataIndex: 'clockIn',
      key: 'clockIn',
    },
    {
      title: 'Clock Out',
      dataIndex: 'clockOut',
      key: 'clockOut',
    },
    {
      title: 'Break Start',
      dataIndex: 'breakStart',
      key: 'breakStart',
    },
    {
      title: 'Break End',
      dataIndex: 'breakEnd',
      key: 'breakEnd',
    },
    {
      title: 'Shift Duration (mins)',
      dataIndex: 'shiftDuration',
      key: 'shiftDuration',
    },
    {
      title: 'Break Duration (mins)',
      dataIndex: 'breakDuration',
      key: 'breakDuration',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        <Tag color={text === 'pending' ? 'orange' : 'green'}>
          {text.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button type="primary" disabled={record.status === 'approved'} onClick={() => handleApprove(record)}>
          Approve
        </Button>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    getCheckboxProps: (record) => ({
      disabled: record.status === 'approved', // Disable selection for approved timesheets
    }),
  };
  const handleSendOffer = async () => {
    try {
     const res =  await updateAssignment(candidate._id, { transition: 'offer' });
      message.success('Offer sent successfully');
      setStatus(res.data.assignment.status)
    } catch (error) {
      message.error('Failed to send offer');
    }
  };

  const handleReject = async () => {
    try {
      await updateAssignment(candidate._id, { transition: 'rejectApplication' });
      message.success('Candidate rejected successfully');
    } catch (error) {
      message.error('Failed to reject candidate');
    }
  };

  const handleAcceptOffer = async () => {
    try {
      await updateAssignment(candidate._id, { transition: 'acceptOffer' });
      message.success('Offer accepted successfully');
    } catch (error) {
      message.error('Failed to accept offer');
    }
  };

  const handleRejectOffer = async () => {
    try {
      await updateAssignment(candidate._id, { transition: 'rejectOffer' });
      message.success('Offer rejected successfully');
    } catch (error) {
      message.error('Failed to reject offer');
    }
  };

  const handleExtendOffer = async () => {
    try {
      await updateAssignment(candidate._id, { transition: 'extendOffer' });
      message.success('Extend offer sent successfully');
    } catch (error) {
      message.error('Failed to send extend offer');
    }
  };
  const renderActions = () => {
    switch (status) {
      case 'idle':
      case 'submitted':
        return (
          <>
            <Button type="primary" onClick={handleSendOffer} style={{ marginRight: 8 }}>Send Offer</Button>
            <Button type="danger" onClick={handleReject}>Reject</Button>
          </>
        );

      case 'offer-sent':
        return (
          <>
          </>
        );
      case 'offer-accepted':
      case 'ongoing':
        return (
          <>
            <Button type="primary" onClick={handleExtendOffer} style={{ marginRight: 8 }}>Extend Offer</Button>
          </>
        );
      case 'offer-rejected':
      case 'extend-offer-sent':
        return (
          <>
          </>
        );
      case 'extend-offer-accepted':
      case 'extend-offer-rejected':
      case 'compliance-in-progress':
      case 'ended':
      default:
        return null;
    }
  };

  return (
    <div className="candidate-details-container">
      <Card>
        <Row justify="space-between">
          <Col>
            <Tag color={
              status === 'idle' ? 'blue' :
              status === 'submitted' ? 'orange' :
              status === 'application-rejected' || status === 'offer-rejected' || status === 'extend-offer-rejected' ? 'red' :
              status === 'offer-sent' || status === 'extend-offer-sent' ? 'purple' :
              status === 'offer-accepted' || status === 'extend-offer-accepted' || status === 'ongoing' ? 'green' :
              status === 'compliance-in-progress' ? 'cyan' :
              status === 'ended' ? 'grey' : 'default'
            }>
              {status.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
            </Tag>
            <Title level={4}>{candidate.employee.fullName}</Title>
            <Link href="#">Approved by {candidate.agency}</Link>
            <br />
            <Text>{candidate.employee.email}</Text>
            <br />
            <Text>{candidate.employee.phone}</Text>
            <br />
            <Text>{`${candidate?.employee?.address?.street}, ${candidate?.employee?.address?.city}, ${candidate?.employee?.address?.state}, ${candidate?.employee?.address?.zipCode}`}</Text>
          </Col>
          <Col>
            {renderActions()}
          </Col>
        </Row>
        <Divider />
        <Title level={5}>Units</Title>
        <div>
          {candidate.employee?.units?.map((unit, index) => (
            <Tag color="cyan" key={index} style={{marginBottom:'5px'}}>{unit}</Tag>
          ))}
        </div>
        <Divider />
        <Title level={5}>Documents</Title>
        <div>
          {documents?.length > 0 ? documents.map((doc, index) => (
            <p key={index}><a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.docType}</a></p>
          )) : <Text>No documents available</Text>}
        </div>
        <Divider />
        <Title level={5}>Off Days</Title>
        {candidate.timeOffs?.length > 0 ? (
          <div>
            {candidate.timeOffs.map((timeOff, index) => (
              <Text key={index}>{`From ${new Date(timeOff.start).toLocaleDateString()} to ${new Date(timeOff.end).toLocaleDateString()}`}</Text>
            ))}
          </div>
        ) : (
          <Text>No off days available</Text>
        )}
        <Divider />
        <Title level={5}>Timesheets</Title>
        <div>
          <Button 
            type="primary" 
            onClick={handleBulkApprove} 
            disabled={selectedRowKeys.length === 0} 
            style={{ marginBottom: 16 }}
          >
            Approve Selected
          </Button>
          <Table 
            rowSelection={rowSelection} 
            columns={columns} 
            dataSource={timesheet} 
            rowKey="_id"
          />
        </div>
        <Divider />
        <Title level={5}>Coversheet</Title>
        <Text>{candidate.coversheet || 'No coversheet available'}</Text>
      </Card>
    </div>
  );
};

export default CandidateDetails;
