import axios from 'axios';
import { message } from 'antd';
import { auth } from './firebase';



const tenantToBaseUrl = {
  'employer-8j61b': '/api/s-org',
  'agency-xd8fa': '/api/s-acy',
};
// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL , 
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
api.interceptors.request.use(
  async (config) => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;

      const tenantId = user.tenantId; // Replace with the correct path if tenantId is nested
      if (tenantId && tenantToBaseUrl[tenantId]) {
        config.baseURL = process.env.REACT_APP_API_BASE_URL + tenantToBaseUrl[tenantId];
      }


    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response) {
      switch (response.status) {
        case 400:
          // message.error('Bad request');
          break;
        case 401:
          // message.error('Unauthorized');
          break;
        case 403:
          // message.error('Forbidden');
          break;
        case 404:
          // message.error('Not found');
          break;
        case 500:
          // message.error('Internal server error');
          break;
        default:
        // message.error('An error occurred');
      }
    } else {
      // message.error('Network error');
    }
    return Promise.reject(error);
  }
);

export const fetchJobs = () => api.get('/jobs');
export const getAssignments = () => api.get('/assignments');
export const getOngoingAssignments = () => api.get('/assignments/ongoing');
export const getJobAssignments = (jobId) => api.get('/assignments/job/' + jobId);

export const updateAssignment = (id, data) => api.put('/assignments/' + id + "/status", data);

export const getAssignmentDocunebt = (id) => api.get('/assignments/' + id + "/documents");


export const postJob = (jobData) => api.post('/jobs', jobData);
export const getOrg = () => api.get('/employer/mine');
export const createOrg = (data) => api.post('/employer', data);
export const updateOrg = (data) => api.put('/employer/mine', data);
export const deleteJob = (jobId) => api.delete(`/jobs/${jobId}`);
export const getAssginmentTimesheet = (jobId) => api.get(`/timesheets/${jobId}`);
export const approveAssginmentTimesheet = (jobId,data) => api.put(`timesheets/${jobId}/timecards/approve`,data);


//agancy 
export const getAgencyOrg = () => api.get('/agency/mine');
export const createAgencyOrg = (data) => api.post('/agency', data);
export const updateAgencyOrg = (data) => api.put('/agency/mine', data);
export const getCompliancePending = () => api.get('/assignments/compliance-pending');
export const getCompliancePendingDocuments = (id) => api.get('/assignments/'+id+'/documents');




export default api;
