import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthProvider';
import SignInScreen from './pages/auth/SignInScreen';
import SignUp from './pages/auth/SignUpScreen';
import ForgotPasswordScreen from './pages/auth/ForgotPasswordScreen';
import VerifyEmail from './pages/auth/VerifyEmail'; // Add this import
import ResetPassword from './pages/auth/ResetPassword'; // Add this import
import DashboardScreen from './pages/employer/dashboard/DashboardScreen';
import AgencyDashboard from './pages/agancy/AgencyDashboard';

import ProfileScreen from './pages/employer/dashboard/ProfileScreen'; 
import NotFound from './pages/not-found/NotFound'; // Update this import

// In App.js

const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/login" />;
};

const App = () => {
  const { tenantType } = useAuth() || {}; // Default to an empty object

  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<SignInScreen />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/dashboard/*"
            element={
              <PrivateRoute>
                <DashboardScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="/agency/*"
            element={
              <PrivateRoute>
                <AgencyDashboard /> {/* Add this component to handle agency views */}
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <ProfileScreen />
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
