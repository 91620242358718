import React, { useState, useEffect } from 'react';
import { Layout, Input, Table, Button, Dropdown, Menu, Typography, Tabs, Tag } from 'antd';
import { PlusOutlined, SearchOutlined, EllipsisOutlined, DownOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import { getCompliancePending, deleteJob } from '../../api';
import './JobManagement.css';

const { Content } = Layout;
const { Title } = Typography;
const { TabPane } = Tabs;

const CompliancePending = () => {
  const [dataSource, setDataSource] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const navigate = useNavigate();
  const location = useLocation(); // Get the location object

  // Fetch jobs every time the component mounts or when the location changes (i.e., forward/back navigation)
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const jobsResponse = await getCompliancePending();
        setDataSource(jobsResponse.data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchInitialData();
  }, [location]); // Add location as a dependency to trigger fetch on navigation

  const handleEdit = (record) => {
    console.log('Edit record:', record);
  };

  const handleDelete = async (id) => {
    try {
      await deleteJob(id);
      setDataSource(dataSource.filter((item) => item.id !== id)); // Fixed to use the correct id
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  const handleViewDetails = (record) => {
    navigate(`/agency/candidate-detail`, { state: { candidate: record  } });
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: ['job', 'code'], // Fix the nested data access
      key: 'code',
    },
    {
      title: 'Assignment Title',
      dataIndex: ['job', 'title'], // Fix the nested data access
      key: 'title',
    },
    {
      title: 'Location',
      dataIndex: ['job', 'address'],
      key: 'location',
      render: (address) => address ? `${address.city}, ${address.state}` : 'N/A', // Handle undefined address
    },
    {
      title: 'Start Date',
      dataIndex: ['job', 'startDate'],
      key: 'startDate',
      render: (startDate) => startDate ? new Date(startDate).toLocaleDateString() : 'N/A', // Handle undefined startDate
    },
    {
      title: 'Status',
      dataIndex: ['job', 'status'],
      key: 'status',
      render: (status) => (
        <Tag color={status === 'Published' ? 'blue' : 'orange'}>{status || '-'}</Tag>
      ),
    },
    {
      title: 'View',
      key: 'view',
      render: (text, record) => <a onClick={() => handleViewDetails(record)}>Details</a>,
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1" onClick={() => handleEdit(record)}>
                Edit
              </Menu.Item>
              <Menu.Item key="2" onClick={() => handleDelete(record.id)}> {/* Fix delete id */}
                Delete
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <EllipsisOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ];

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  return (
    <Content
      className="site-layout-background"
      style={{
        margin: '24px 16px',
        padding: 24,
        minHeight: 280,
        backgroundColor: '#fff',
      }}
    >
      <div className="job-management-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <div>
          <Title level={3}>Compliance Pending Assignments</Title>
        </div>
      </div>
      <Tabs defaultActiveKey="1" size="large" onChange={handleTabChange}>
        <TabPane tab={<span>All <Tag>{dataSource.length}</Tag></span>} key="1">
          <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Input
              placeholder="Search assignments"
              prefix={<SearchOutlined />}
              style={{ width: '300px' }}
            />
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">Sort by Date</Menu.Item>
                  <Menu.Item key="2">Sort by Title</Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button>
                Sort by <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          <Table dataSource={dataSource} columns={columns} pagination={{ pageSize: 10 }} />
        </TabPane>
      </Tabs>
    </Content>
  );
};

export default CompliancePending;
