import React from 'react';
import { Layout, Menu, Card, Col, Row, Typography, Button } from 'antd';
import {
  HomeOutlined,
  FileTextOutlined,
  CheckCircleOutlined,
  DollarOutlined,
  UserOutlined,
  BellOutlined,
} from '@ant-design/icons';
import './Dashboard.css';

const { Header, Sider, Content } = Layout;
const { Title, Paragraph } = Typography;

const Dashboard = () => {
  return (
    <Content
      className="site-layout-background"
      style={{
        margin: '24px 16px',
        padding: 24,
        minHeight: 280,
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Card title="Total Assignments" bordered={false}>
            32
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Active Engagement" bordered={false}>
            32
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '16px' }}>
        <Col span={12}>
          <Card title="Pending Candidates" bordered={false}>
            32
          </Card>
        </Col>
        <Col span={12}>
          <Card title="New Candidate" bordered={false}>
            32
          </Card>
        </Col>
      </Row>
      <div style={{ marginTop: '24px' }}>
        <Card title="Alerts" bordered={false}>
          <ul>
            <li>One of your active engagements has 1 open position.</li>
            <li>One of your Assignments start date is close and has 1 open position.</li>
          </ul>
        </Card>
      </div>
    </Content>

  );
};

export default Dashboard;
