import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  signInWithPopup,
  googleProvider,
  setAuthTenant,
} from './firebase';

// Create Auth Context
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tenantType, setTenantType] = useState(null); // New state for tenant type
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const setTenant = (tenantType) => {
    let tenantId;
    setTenantType(tenantType); // Store the tenant type
    switch (tenantType) {
      case 'agency':
        tenantId = process.env.REACT_APP_TENANT_AGENCY;
        break;
      case 'employee':
        tenantId = process.env.REACT_APP_TENANT_EMPLOYEE;
        break;
      case 'employer':
        tenantId = process.env.REACT_APP_TENANT_EMPLOYER;
        break;
      default:
        tenantId = process.env.REACT_APP_TENANT_MASTER;
    }
    setAuthTenant(tenantId);
  };

  const login = async (email, password, tenantType) => {
    setTenant(tenantType);
    await signInWithEmailAndPassword(auth, email, password);
    navigate(tenantType === 'agency' ? '/agency' : '/dashboard');
  };

  const loginWithGoogle = async (tenantType) => {
    setTenant(tenantType);
    await signInWithPopup(auth, googleProvider);
    navigate(tenantType === 'agency' ? '/agency' : '/dashboard');
  };

  const signup = async (email, password, tenantType) => {
    setTenant(tenantType);
    await createUserWithEmailAndPassword(auth, email, password);
    await login(email, password, tenantType);
  };

  const logout = async () => {
    await signOut(auth);
    navigate('/login');
  };

  const value = {
    currentUser,
    tenantType, // Provide tenant type in the context
    login,
    loginWithGoogle,
    signup,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

