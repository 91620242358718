import React from 'react';
import { useAuth } from '../../../AuthProvider';

const ProfileScreen = () => {
  const { currentUser } = useAuth();

  return (
    <div>
      <h2>Profile</h2>
      <p>Name: {currentUser?.displayName || 'N/A'}</p>
      <p>Email: {currentUser?.email}</p>
      {/* Add more profile details as needed */}
    </div>
  );
};

export default ProfileScreen;
