import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Row, Col, Typography, Collapse, DatePicker, Breadcrumb, Modal, message, InputNumber, Descriptions, Divider,Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { postJob } from './../../../api'; // Import your API provider
import './PostJob.css';

const { Option } = Select;
const { Title, Text } = Typography;
const { Panel } = Collapse;

const states = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 
  'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 
  'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 
  'New sJersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 
  'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 
  'West Virginia', 'Wisconsin', 'Wyoming'
];
const DocumentType = {
  ACLS: 'ACLS',
  BLS: 'BLS',
  NIHSS: 'NIHSS',
  TNCC: 'TNCC',
  Resume: 'Resume',
  ReliasChecklist: 'Relias-Checklist',
  ID: 'ID',
  RNLicense: 'RN-License',
};

const generateRandomCode = () => {
  return `JOB-${Math.floor(100000 + Math.random() * 900000)}`;
};

const PostJobScreen = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [mandatoryDocuments, setMandatoryDocuments] = useState([]);

  const [jobData, setJobData] = useState(null);

  useEffect(() => {
    form.setFieldsValue({ code: generateRandomCode() });
  }, [form]);

  const onFinish = (values) => {
    setJobData(values);
    setPreviewVisible(true);
  };
  const handleDocumentChange = (checkedValues) => {
    setMandatoryDocuments(checkedValues);
  };
  const handlePublish = async () => {
    try {
      const response = await postJob({
        title: jobData.jobTitle,
        category: jobData.jobCategory,
        code: jobData.code,
        statusReason: jobData.statusReason,
        reason: {
          description: jobData.reasonDescription,
          details: jobData.reasonDetails
        },
        department: jobData.department,
        keywords: jobData.keywords.split(',').map(keyword => keyword.trim()),
        numberOfOpenings: jobData.numberOfPositions,
        duties: jobData.jobDescription,
        requiredSkills: jobData.requiredSkills.split(',').map(skill => skill.trim()),
        preferredSkills: jobData.preferredSkills.split(',').map(skill => skill.trim()),
        languages: jobData.languages.split(',').map(language => language.trim()),
        degree: jobData.degree,
        managerName: jobData.manager,
        address: {
          street: jobData.street,
          city: jobData.city,
          state: jobData.state,
          zipCode: jobData.zipCode,
          country:"us",
          coordinates: [parseFloat('-122.4194'), parseFloat('37.7749')]
        },
        salary: {
          base: jobData.baseSalary,
          callBack: jobData.callBackSalary,
          holiday: jobData.holidaySalary,
          onCall: jobData.onCallSalary,
          overtime: jobData.overtimeSalary,
          regular: jobData.regularSalary,
          currency: "USD"
        },
        hoursPerDay: jobData.hoursPerDay,
        daysPerWeek: jobData.daysPerWeek,
        scheduleNotes: jobData.scheduleNotes,
        status: "open",
        unit: jobData.unit,
        type: jobData.type,
        isFloat: jobData.float === 'yes',
        shift: jobData.shift,
        startDate: jobData.startDate.format('YYYY-MM-DD'),
        endDate: jobData.endDate.format('YYYY-MM-DD'),
        mandatoryDocuments: mandatoryDocuments,
      });
      message.success('Job posted successfully!');
      console.log('Response:', response.data);
      navigate('/dashboard');
    } catch (error) {
      message.error('Failed to post job');
      console.error('Error:', error);
    }
  };

  return (
    <div className="post-job-container" style={{ padding: '16px', background: '#ffff' }}>
      <Breadcrumb style={{ marginBottom: '16px' }}>
        <Breadcrumb.Item>Assignment List</Breadcrumb.Item>
        <Breadcrumb.Item>Create New Assignment</Breadcrumb.Item>
      </Breadcrumb>
      <Title level={2}>Create New Assignment</Title>
      <p>Please fill in the details to create a new assignment.</p>
      <Form
        form={form}
        name="post-job"
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          // jobTitle: 'Software Engineer',
          // jobCategory: 'Engineering',
          // statusReason: 'New Position',
          // reasonDescription: 'Expanding Team',
          // reasonDetails: 'Adding new team members for a new project',
          // manager: 'John Doe',
          // department: 'IT',
          // numberOfPositions: '3',
          // jobDescription: 'Develop and maintain software applications',
          // requiredSkills: 'JavaScript, React, Node.js',
          // preferredSkills: 'AWS, Docker',
          // languages: 'English, Spanish',
          // degree: "Bachelor's Degree in Computer Science",
          // keywords: 'Software, Engineering, Development',
          // startDate: moment('2023-01-01', 'YYYY-MM-DD'),
          // endDate: moment('2023-12-31', 'YYYY-MM-DD'),
          // hoursPerDay: 8,
          // daysPerWeek: '5',
          // float: 'no',
          // shift: 'day',
          // scheduleNotes: 'Flexible working hours',
          // street: '123 Main St',
          // city: 'San Francisco',
          // state: 'California',
          // zipCode: '94105',
          // baseSalary: 100000,
          // callBackSalary: 1000,
          // holidaySalary: 2000,
          // onCallSalary: 1500,
          // overtimeSalary: 3000,
          // regularSalary: 80000,
          // type: 'contract',
          // unit: 'NICU'
        }}
      >
        <Collapse defaultActiveKey={['1', '2', '3', '4', '5']}>
          <Panel header="Job Description" key="1">
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="jobTitle" label="Job Title" rules={[{ required: true, message: 'Please enter the job title!' }]}>
                  <Input placeholder="Job Title" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="jobCategory" label="Job Category" rules={[{ required: true, message: 'Please enter the job category!' }]}>
                  <Input placeholder="Job Category" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="code" label="Code" hidden>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="statusReason" label="Status Reason" rules={[{ required: true, message: 'Please enter the status reason!' }]}>
                  <Input placeholder="Status Reason" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="reasonDescription" label="Reason Description" rules={[{ required: true, message: 'Please enter the reason description!' }]}>
                  <Input placeholder="Reason Description" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="reasonDetails" label="Reason Details" rules={[{ required: true, message: 'Please enter the reason details!' }]}>
                  <Input placeholder="Reason Details" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="manager" label="Manager" rules={[{ required: true, message: 'Please enter the manager!' }]}>
                  <Input placeholder="Manager" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="department" label="Department" rules={[{ required: true, message: 'Please enter the department!' }]}>
                  <Input placeholder="Department" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="numberOfPositions" label="Number of Positions" rules={[{ required: true, message: 'Please select the number of positions!' }]}>
                  <Select placeholder="Select">
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="10">10</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="jobDescription" label="Job Description" rules={[{ required: true, message: 'Please enter the job description!' }]}>
                  <Input.TextArea rows={4} placeholder="Job Description" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="requiredSkills" label="Required Skills" rules={[{ required: true, message: 'Please enter the required skills!' }]}>
                  <Input placeholder="Required Skills (comma separated)" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="preferredSkills" label="Preferred Skills" rules={[{ required: true, message: 'Please enter the preferred skills!' }]}>
                  <Input placeholder="Preferred Skills (comma separated)" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="languages" label="Languages" rules={[{ required: true, message: 'Please enter the languages!' }]}>
                  <Input placeholder="Languages (comma separated)" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="degree" label="Degree" rules={[{ required: true, message: 'Please enter the degree!' }]}>
                  <Input placeholder="Degree" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="keywords" label="Keywords" rules={[{ required: true, message: 'Please enter the keywords!' }]}>
                  <Input placeholder="Keywords (comma separated)" />
                </Form.Item>
              </Col>
            </Row>
          </Panel>

          <Panel header="Schedule" key="2">
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="startDate" label="Start Date" rules={[{ required: true, message: 'Please select the start date!' }]}>
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="endDate" label="End Date" rules={[{ required: true, message: 'Please select the end date!' }]}>
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="hoursPerDay" label="Hours Per Day" rules={[{ required: true, message: 'Please enter the hours per day!' }]}>
                  <InputNumber placeholder="Hours Per Day" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="daysPerWeek" label="Days Per Week" rules={[{ required: true, message: 'Please select days per week!' }]}>
                  <Select placeholder="Select">
                    <Option value="3">3</Option>
                    <Option value="5">5</Option>
                    <Option value="6">6</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="float" label="Float" rules={[{ required: true, message: 'Please select float!' }]}>
                  <Select placeholder="Select">
                    <Option value="no">No</Option>
                    <Option value="yes">Yes</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="shift" label="Shift" rules={[{ required: true, message: 'Please select shift!' }]}>
                  <Select placeholder="Select">
                    <Option value="day">Day</Option>
                    <Option value="night">Night</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="scheduleNotes" label="Schedule Notes">
              <Input.TextArea rows={4} placeholder="Schedule Notes" />
            </Form.Item>
          </Panel>

          <Panel header="Address" key="3">
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="street" label="Street" rules={[{ required: true, message: 'Please enter the street!' }]}>
                  <Input placeholder="Street" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="city" label="City" rules={[{ required: true, message: 'Please enter the city!' }]}>
                  <Input placeholder="City" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="state" label="State" rules={[{ required: true, message: 'Please enter the state!' }]}>
                  <Select placeholder="Select">
                    {states.map(state => (
                      <Option key={state} value={state}>{state}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="zipCode" label="Zip Code" rules={[{ required: true, message: 'Please enter the zip code!' }]}>
                  <Input placeholder="Zip Code" />
                </Form.Item>
              </Col>
            </Row>
          </Panel>

          <Panel header="Salary Details" key="4">
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="baseSalary" label="Base Salary" rules={[{ required: true, message: 'Please enter the base salary!' }]}>
                  <InputNumber
                    placeholder="Base Salary"
                    min={0}
                    style={{ width: '100%' }}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="callBackSalary" label="Call Back Salary" rules={[{ required: true, message: 'Please enter the call back salary!' }]}>
                  <InputNumber
                    placeholder="Call Back Salary"
                    min={0}
                    style={{ width: '100%' }}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="holidaySalary" label="Holiday Salary" rules={[{ required: true, message: 'Please enter the holiday salary!' }]}>
                  <InputNumber
                    placeholder="Holiday Salary"
                    min={0}
                    style={{ width: '100%' }}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="onCallSalary" label="On Call Salary" rules={[{ required: true, message: 'Please enter the on call salary!' }]}>
                  <InputNumber
                    placeholder="On Call Salary"
                    min={0}
                    style={{ width: '100%' }}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="overtimeSalary" label="Overtime Salary" rules={[{ required: true, message: 'Please enter the overtime salary!' }]}>
                  <InputNumber
                    placeholder="Overtime Salary"
                    min={0}
                    style={{ width: '100%' }}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="regularSalary" label="Regular Salary" rules={[{ required: true, message: 'Please enter the regular salary!' }]}>
                  <InputNumber
                    placeholder="Regular Salary"
                    min={0}
                    style={{ width: '100%' }}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>

          <Panel header="Job Details" key="5">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Please select the job type!' }]}>
                  <Select placeholder="Select">
                    <Option value="contract">Contract</Option>
                    <Option value="per-diem">per-diem</Option>
                    <Option value="locum">locum</Option>
                    <Option value="perm">perm</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="unit" label="Unit" rules={[{ required: true, message: 'Please select the unit!' }]}>
                  <Select placeholder="Select">
                    <Option value="Intensive care unit">Intensive care unit</Option>
                    <Option value="Psychiatry">Psychiatry</Option>
                    <Option value="Surgery">Surgery</Option>
                    <Option value="Radiology">Radiology</Option>
                    <Option value="Hematology">Hematology</Option>
                    <Option value="Oncology">Oncology</Option>
                    <Option value="Emergency Department">Emergency Department</Option>
                    <Option value="NICU">NICU</Option>
                    <Option value="Orthopedics">Orthopedics</Option>
                    <Option value="PACU">PACU</Option>
                    <Option value="Physical therapy">Physical therapy</Option>
                    <Option value="Burn">Burn</Option>
                    <Option value="Internal medicine">Internal medicine</Option>
                    <Option value="Emergency Medicine">Emergency Medicine</Option>
                    <Option value="Gynecology">Gynecology</Option>
                    <Option value="Pathology">Pathology</Option>
                    <Option value="Outpatient department">Outpatient department</Option>
                    <Option value="Pediatrics">Pediatrics</Option>
                    <Option value="Perioperative">Perioperative</Option>
                    <Option value="Anesthetics">Anesthetics</Option>
                    <Option value="Infant care Unit">Infant care Unit</Option>
                    <Option value="Operating rooms">Operating rooms</Option>
                    <Option value="Pharmacy">Pharmacy</Option>
                    <Option value="Long-term care">Long-term care</Option>
                    <Option value="Home care">Home care</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Panel>

          <Panel header="Mandatory Documents" key="6">
            <Form.Item label="Select Mandatory Documents">
              <Checkbox.Group
                options={Object.values(DocumentType)}
                onChange={handleDocumentChange}
              />
            </Form.Item>
          </Panel>
        </Collapse>



        <Form.Item style={{ marginTop: '16px', textAlign: 'right' }}>
          <Button style={{ marginRight: '8px' }} onClick={() => form.resetFields()}>
            Save as draft
          </Button>
          <Button type="primary" htmlType="submit">
            Post (publish)
          </Button>
        </Form.Item>
      </Form>

      <Modal
        visible={previewVisible}
        title="Job Preview"
        onCancel={() => setPreviewVisible(false)}
        footer={[
          <Button key="edit" onClick={() => setPreviewVisible(false)}>
            Edit
          </Button>,
          <Button key="publish" type="primary" onClick={handlePublish}>
            Publish
          </Button>,
        ]}
      >
        <Descriptions size="small" bordered column={1}>
          <Descriptions.Item label="Job Title">{jobData?.jobTitle}</Descriptions.Item>
          <Descriptions.Item label="Category">{jobData?.jobCategory}</Descriptions.Item>
          <Descriptions.Item label="Code">{jobData?.code}</Descriptions.Item>
          <Descriptions.Item label="Status Reason">{jobData?.statusReason}</Descriptions.Item>
          <Descriptions.Item label="Reason Description">{jobData?.reasonDescription}</Descriptions.Item>
          <Descriptions.Item label="Reason Details">{jobData?.reasonDetails}</Descriptions.Item>
          <Descriptions.Item label="Manager">{jobData?.manager}</Descriptions.Item>
          <Descriptions.Item label="Department">{jobData?.department}</Descriptions.Item>
          <Descriptions.Item label="Number of Positions">{jobData?.numberOfPositions}</Descriptions.Item>
          <Descriptions.Item label="Job Description">{jobData?.jobDescription}</Descriptions.Item>
          <Descriptions.Item label="Required Skills">{jobData?.requiredSkills}</Descriptions.Item>
          <Descriptions.Item label="Preferred Skills">{jobData?.preferredSkills}</Descriptions.Item>
          <Descriptions.Item label="Languages">{jobData?.languages}</Descriptions.Item>
          <Descriptions.Item label="Degree">{jobData?.degree}</Descriptions.Item>
          <Descriptions.Item label="Keywords">{jobData?.keywords}</Descriptions.Item>
          <Descriptions.Item label="Start Date">{jobData?.startDate?.format('YYYY-MM-DD')}</Descriptions.Item>
          <Descriptions.Item label="End Date">{jobData?.endDate?.format('YYYY-MM-DD')}</Descriptions.Item>
          <Descriptions.Item label="Hours Per Day">{jobData?.hoursPerDay}</Descriptions.Item>
          <Descriptions.Item label="Days Per Week">{jobData?.daysPerWeek}</Descriptions.Item>
          <Descriptions.Item label="Float">{jobData?.float}</Descriptions.Item>
          <Descriptions.Item label="Shift">{jobData?.shift}</Descriptions.Item>
          <Descriptions.Item label="Schedule Notes">{jobData?.scheduleNotes}</Descriptions.Item>
          <Descriptions.Item label="Street">{jobData?.street}</Descriptions.Item>
          <Descriptions.Item label="City">{jobData?.city}</Descriptions.Item>
          <Descriptions.Item label="State">{jobData?.state}</Descriptions.Item>
          <Descriptions.Item label="Zip Code">{jobData?.zipCode}</Descriptions.Item>
          <Descriptions.Item label="Base Salary">${jobData?.baseSalary?.toLocaleString()}</Descriptions.Item>
          <Descriptions.Item label="Call Back Salary">${jobData?.callBackSalary?.toLocaleString()}</Descriptions.Item>
          <Descriptions.Item label="Holiday Salary">${jobData?.holidaySalary?.toLocaleString()}</Descriptions.Item>
          <Descriptions.Item label="On Call Salary">${jobData?.onCallSalary?.toLocaleString()}</Descriptions.Item>
          <Descriptions.Item label="Overtime Salary">${jobData?.overtimeSalary?.toLocaleString()}</Descriptions.Item>
          <Descriptions.Item label="Regular Salary">${jobData?.regularSalary?.toLocaleString()}</Descriptions.Item>
          <Descriptions.Item label="Type">{jobData?.type}</Descriptions.Item>
          <Descriptions.Item label="Unit">{jobData?.unit}</Descriptions.Item>
        </Descriptions>
      </Modal>
    </div>
  );
};

export default PostJobScreen;
