import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
import './auth.css'; // Import the CSS file

const ResetPassword = () => {
  const [reset, setReset] = useState(false);
  const auth = getAuth();
  const location = useLocation();
  const actionCode = new URLSearchParams(location.search).get('oobCode');

  const onFinish = async (values) => {
    if (values.password !== values.confirmPassword) {
      message.error('Passwords do not match.');
      return;
    }
    try {
      await confirmPasswordReset(auth, actionCode, values.password);
      setReset(true);
      message.success('Password has been reset successfully!');
    } catch (error) {
      message.error('Failed to reset password. The link may be expired or invalid.');
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-background"></div>
      <div className="auth-form-container">
        <h2>Reset Password</h2>
        {!reset ? (
          <Form name="reset-password" onFinish={onFinish} layout="vertical">
            <Form.Item name="password" rules={[{ required: true, message: 'Please enter your new password!' }]}>
              <Input.Password size="large" placeholder="Enter your new password" />
            </Form.Item>
            <Form.Item name="confirmPassword" rules={[{ required: true, message: 'Please confirm your new password!' }]}>
              <Input.Password size="large" placeholder="Confirm your new password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="auth-form-button" size="large">
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <div>
            <p>Your password has been reset. You can now <Link to="/login">login</Link> with your new password.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
