import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, message, Select } from 'antd';
import { Link } from 'react-router-dom';
import { useAuth } from '../../AuthProvider';
import './auth.css'; // Import the CSS file

const { Option } = Select;

const SignInScreen = () => {
  const { login, loginWithGoogle } = useAuth();
  const [tenantType, setTenantType] = useState('employer');

  const onFinish = async (values) => {
    try {
      await login(values.email, values.password, tenantType);
      message.success('Login successful!');
    } catch (error) {
      switch (error.code) {
        case 'auth/user-not-found':
          message.error('No user found with this email.');
          break;
        case 'auth/wrong-password':
          message.error('Incorrect password.');
          break;
        case 'auth/too-many-requests':
          message.error('Too many attempts. Please try again later.');
          break;
        default:
          message.error('Failed to login. Please try again.');
      }
      console.error('Error:', error);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle(tenantType);
      message.success('Login with Google successful!');
    } catch (error) {
      message.error('Failed to login with Google. Please try again.');
      console.error('Error:', error);
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-background"></div>
      <div className="auth-form-container">
        <h2>Login</h2>
        <Form name="login" onFinish={onFinish} layout="vertical">
          <Form.Item name="tenantType" label="Login as" initialValue="employer">
            <Select onChange={setTenantType} size="large">
              <Option value="employer">Healthcare Facility</Option>
              <Option value="agency">Agency</Option>
            </Select>
          </Form.Item>
          <Form.Item name="email" rules={[{ required: true, message: 'Please enter your email!' }]}>
            <Input size="large" placeholder="Enter your email" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Please enter your password!' }]}>
            <Input.Password size="large" placeholder="Enter your password" />
          </Form.Item>
          <Form.Item>
            <Checkbox>Remember me</Checkbox>
            <Link className="auth-form-forgot" to="/forgot-password">
              Forgot your password?
            </Link>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="auth-form-button" size="large">
              Login
            </Button>
          </Form.Item>
          {/* <Form.Item>
            <Button type="default" onClick={handleGoogleLogin} className="auth-form-button" size="large">
              Login with Google
            </Button>
          </Form.Item> */}
          <div>
            Don't have an account?  
            <Link to="/signup" style={{color:'#006971',fontWeight:'bold'}}> Sign up</Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SignInScreen;
