import React, { useEffect, useState } from 'react';
import { Layout, Form, Input, Button, Select, Row, Col, message, Space, Divider, Breadcrumb, Typography } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { createAgencyOrg, updateAgencyOrg, getAgencyOrg } from '../../api';
import { storage, ref, uploadBytes, getDownloadURL } from '../../firebase';

const usStates = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
  'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
  'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
  'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
  'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
  'West Virginia', 'Wisconsin', 'Wyoming'
];

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const CreateOrgScreen = ({ organizationId }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [coversheetFields, setCoversheetFields] = useState([]);
  const [logoFile, setLogoFile] = useState(null);
  const [haveOrg, setHaveOrg] = useState(false);

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const response = await getAgencyOrg();
        const data = response.data;
        setHaveOrg(true)
        form.setFieldsValue({
          title: data.title,
          type: data.type,
          phone: data.phone,
          email: data.email,
          website: data.website,
          street: data.address.street,
          state: data.address.state,
          zipCode: data.address.zipCode,
        });

        setCoversheetFields(data.coversheetFields || []);
      } catch (error) {
        //message.error('Failed to load organization data.');
      }
    };

    fetchOrgData();
  }, []);

  const handleUpload = async (file, organizationId) => {
    const storageRef = ref(storage, `public/${organizationId}/logo.jpg`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  };

  const handleDetailSubmit = async () => {
    try {
      const values = await form.validateFields();
      let website = values.website;

      if (!/^https?:\/\//i.test(website)) {
        website = `https://${website}`;
      }

      let data = {
        ...values,
        website,
        address: {
          street: values.street,
          state: values.state,
          zipCode: values.zipCode,
          country: 'US',
        },
        coversheetFields,
      };

      data.logo = undefined
      data.street = undefined
      data.state = undefined
      data.zipCode = undefined

      let response;
      if (haveOrg) {
        response = await updateAgencyOrg(organizationId, data);
      } else {
        response = await createAgencyOrg(data);
      }
      const newOrganizationId = response.data.id;

      if (logoFile) {
        const logoUrl = await handleUpload(logoFile, newOrganizationId);
        await updateAgencyOrg(newOrganizationId, { logoUrl });
      }
      message.success('Organization saved successfully!');
      navigate('/agency');
    } catch (error) {
      message.error(error?.response?.data?.error || 'Failed to save organization.');
    }
  };

  const handleFileChange = (e) => {
    setLogoFile(e.target.files[0]);
  };

  const addCoversheetField = () => {
    setCoversheetFields([...coversheetFields, { dataType: '', title: '', required: false, options: [] }]);
  };

  const updateCoversheetField = (index, field, value) => {
    const updatedFields = [...coversheetFields];
    updatedFields[index][field] = value;
    setCoversheetFields(updatedFields);
  };

  const removeCoversheetField = (index) => {
    const updatedFields = coversheetFields.filter((_, i) => i !== index);
    setCoversheetFields(updatedFields);
  };

  return (
    <div className="post-job-container" style={{ padding: '16px', background: '#ffff' }}>
      <Breadcrumb style={{ marginBottom: '16px' }}>
        <Breadcrumb.Item>Assignment List</Breadcrumb.Item>
        <Breadcrumb.Item>Create New Assignment</Breadcrumb.Item>
      </Breadcrumb>
      <Title level={2}>Agency Setting</Title>
      <p>Please fill in the details.</p>
      <Layout style={{ minHeight: '100vh', background: '#fff' }}>
        <Content style={{ padding: 24 }}>
          <Form form={form} layout="vertical" onFinish={handleDetailSubmit}>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Please enter the title' }]}
            >
              <Input placeholder="Enter title" />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[{ required: true, message: 'Please enter the type' }]}
                >
                  <Input placeholder="Enter type" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="phone"
                  label="Phone"
                  rules={[{ required: true, message: 'Please enter the phone number' }]}
                >
                  <Input placeholder="Enter phone number" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required: true, message: 'Please enter a valid email', type: 'email' }]}
                >
                  <Input placeholder="Enter email address" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="website"
                  label="Website"
                  rules={[{ required: true, message: 'Please enter a valid website URL' }]}
                >
                  <Input placeholder="Enter website URL" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="street"
                  label="Street"
                  rules={[{ required: true, message: 'Please enter the street' }]}
                >
                  <Input placeholder="Enter street" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[{ required: true, message: 'Please enter the state' }]}
                >
                  <Select placeholder="Select state">
                    {usStates.map((state) => (
                      <Option key={state} value={state}>{state}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="zipCode"
                  label="Zip Code"
                  rules={[{ required: true, message: 'Please enter the zip code' }]}
                >
                  <Input placeholder="Enter zip code" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="logo"
                  label="Logo"
                >
                  <input type="file" onChange={handleFileChange} />
                </Form.Item>
              </Col>
            </Row>
            <Divider>Coversheet Fields</Divider>
            {coversheetFields.map((field, index) => (
              <Space key={index} direction="vertical" style={{ width: '100%' }}>
                <Row gutter={16}>
                  <Col span={7}>
                    <Form.Item
                      label="Field Title"
                      required
                    >
                      <Input
                        value={field.title}
                        onChange={(e) => updateCoversheetField(index, 'title', e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      label="Data Type"
                      required
                    >
                      <Select
                        value={field.dataType}
                        onChange={(value) => updateCoversheetField(index, 'dataType', value)}
                      >
                        <Option value="text">Text</Option>
                        <Option value="number">Number</Option>
                        <Option value="email">Email</Option>
                        <Option value="date">Date</Option>
                        <Option value="url">URL</Option>
                        <Option value="multiple-choice">Multiple Choice</Option>
                        <Option value="single-choice">Single Choice</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      label="Required"
                      required
                    >
                      <Select
                        value={field.required ? 'Yes' : 'No'}
                        onChange={(value) => updateCoversheetField(index, 'required', value === 'Yes')}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                      type="danger"
                      icon={<MinusCircleOutlined />}
                      onClick={() => removeCoversheetField(index)}
                    >
                      Remove
                    </Button>
                  </Col>
                </Row>
                {(field.dataType === 'multiple-choice' || field.dataType === 'single-choice') && (
                  <Form.Item
                    label="Options"
                    required
                  >
                    <Input
                      placeholder="Enter options separated by commas"
                      value={field.options.join(', ')}
                      onChange={(e) => updateCoversheetField(index, 'options', e.target.value.split(',').map(option => option.trim()))}
                    />
                  </Form.Item>
                )}
              </Space>
            ))}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button type="dashed" onClick={addCoversheetField} style={{ marginTop: '20px', height: '60px', borderRadius: '100px' }}>
                + Add Coversheet Field
              </Button>
              <Button type="primary" htmlType="submit" style={{ marginTop: '20px', width: '200px', height: '60px', borderRadius: '100px' }}>
                Save
              </Button>
            </div>
          </Form>
        </Content>
      </Layout>
    </div>
  );
};

export default CreateOrgScreen;
